import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography, Divider } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "1200px",
    margin: "0px auto",
  },
  divider: {
    backgroundColor: "#00806F",
    border: "1px solid #00806F",
    width: "72px",
    height: "2px",
    marginTop: "5px",
  },
}))

export default function References() {
  const classes = useStyles()

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryReferencesContents {
          data {
            title {
              hr
            }
            subtitle {
              hr
            }
            logos {
              iv {
                url
              }
            }
          }
        }
      }
    }
  `)

  const references = squidex.queryReferencesContents[0]

  return (
    <div>
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
          style={{ padding: "2rem" }}
        >
          <Grid
            item
            xs={12}
            style={{
              margin: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">{references.data.title.hr}</Typography>
            <Divider className={classes.divider} />
            <Typography style={{ padding: "0.5rem" }}>
              {references.data.subtitle.hr}
            </Typography>
          </Grid>
          {references.data.logos.iv.map((logo, index) => (
            <Grid
              item
              md={2}
              xs={6}
              key={index}
              style={{ padding: "0.5rem", textAlign: "center" }}
            >
              <img
                src={logo.url}
                style={{ maxHeight: "99px", maxWidth: "150px" }}
                alt="image"
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}
