import React from "react"
import BacktoTop from "react-back-to-top-button"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowUpOutlined from "@material-ui/icons/KeyboardArrowUpOutlined"

const useStyles = makeStyles(theme => ({
  toTop: {
    height: "60px",
    width: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    opacity: "0.85",
    borderRadius: "50%",
    color: "#fff",
    fontSize: "1rem",
    border: "1px solid #048387",
  },
}))

export default function BackToTop() {
  const classes = useStyles()
  return (
    <BacktoTop showOnScrollUp showAt={100} speed={1500} easing="easeInOutQuint">
      <span className={classes.toTop}>
       <KeyboardArrowUpOutlined style={{ width:"40px",height:"40px", color:"#048387" }}/>
      </span>
    </BacktoTop>
  )
}
