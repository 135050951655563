import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography, Divider } from "@material-ui/core"
import classNames from "classnames"

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "1200px",
    margin: "0px auto",
  },
  grid: {
    padding: "0rem 1rem 0rem 1rem",
  },
  gridItem: {
    textAlign: "center",
    paddingBottom: "1rem",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justify: "center",
    alignItems: "center",
    paddingBottom: "2rem",
    paddingTop: "2rem",
  },
  title: {
    fontSize: "32px",
    fontWeight: 700,
  },
  divider: {
    backgroundColor: "#00806F",
    border: "1px solid #00806F",
    width: "77px",
    height: "2px",
    marginTop: "15px",
  },
  subtitle: {
    marginTop: "15px",
    fontSize: "16px",
  },
  descriptionImageContainer: {
    padding: "1rem",
  },
  descriptionContainer: {
    padding: "1rem",
  },
  descriptionText: {
    fontSize: "18px",
    padding: "0.5rem",
  },
  descriptionImage: {
    float: "left",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    padding: "1rem",
  },
}))

export default function AboutUs() {
  const classes = useStyles()

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryAboutUsContents {
          data {
            title {
              hr
            }
            subtitle {
              hr
            }
            image {
              iv {
                url
              }
            }
            descriptionOne {
              hr
            }
            descriptionTwo {
              hr
            }
            descriptionThree {
              hr
            }
          }
        }
      }
    }
  `)

  const aboutUs = squidex.queryAboutUsContents[0]

  return (
    <div>
      <div className={classes.container}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid
            item
            xs={12}
            className={classNames(classes.gridItem, classes.titleContainer)}
          >
            <Typography className={classes.title}>
              {aboutUs.data.title.hr}
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.subtitle}>
              {aboutUs.data.subtitle.hr}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                md={6}
                xs={12}
                className={classes.descriptionContainer}
              >
                {" "}
                <Typography align="justify" className={classes.descriptionText}>
                  {aboutUs.data.descriptionOne.hr}
                </Typography>
                <Typography align="justify" className={classes.descriptionText}>
                  {aboutUs.data.descriptionTwo.hr}
                </Typography>
                <Typography align="justify" className={classes.descriptionText}>
                  {aboutUs.data.descriptionThree.hr}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.imageContainer}>
                {" "}
                <img
                  src={aboutUs.data.image.iv[0].url}
                  className={classes.descriptionImage}
                  alt="about us"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
