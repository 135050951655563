import React from "react"
import Layout from "../components/layout"
import Jumbotron from "../components/jumbotron"
import Characteristics from "../components/characteristics"
import AboutUs from "../components/aboutUs"
import Services from "../components/services"
import Quote from "../components/quote"
import References from "../components/references"
import Contact from "../components/contact"
import Header from "../components/header"
import Footer from "../components/footer"
import BackToTop from "../components/backToTop"
import { ScrollingProvider, Section } from "react-scroll-section"
import { Helmet } from "react-helmet"

const IndexPage = ({ intl }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="hr" />
        <meta
          name="description"
          content="Suvremeni ured za usluge računovodstva, knjigovodstva i poslovnog savjetovanja, s 20 godina iskustva. Dostupni smo na lokaciji u Prelogu i putem virtualnog ureda."
        />
        <meta
          name="keywords"
          content="Računovodstvo Pongrac,Računovodstvo Pongrac d.o.o,računovodstvo,racunovodstvo pongrac,racunovodstvo,računovodstvo pongrac,Računovodstvo,knjigovodstvo,poslovno savjetovanje,Prelog,Međimurje,Čakovec,financije,revizija,obračun PDV-a,plaća,predaja izvještaja,GFI,PD,otvaranje tvrtke firme obrt,porezna,fina,čakovec,JOPPD,kvalitetno knjigovodstvo,stručno,certificirano,pouzdano,knjigovodstveni servis,pongrac,dohodak,porez na dobit,oporezivanje,vođenje, knjiženje,kontiranje,pouzdane usluge,Knjigovodstvo,Knjigovotstvo,knjigovotstvo,Individualno prilagođeni kontni plan,Evidentiranje poslovnih događaja,Evidencija kupaca i dobavljača,Evidencija osnovnih sredstava,Izvještavanje,Izrada internih izvještaja prema potrebama klijenta,Izrada propisanih izvještaja za državnu statistiku,Izrada godišnjih računovodstvenih izvještaja,Porezni obračuni,Obračun PDV-a,Godišnji porezni obračuni,Aktivacija ID broja i obračun PDV-a za nerezidente,Računovodstveni nadzor i savjetovanje,Savjetovanje o usklađenosti dokumentacije s poreznim propisima i računovodstvenim standardima,Savjetovanje u vezi s optimalnim računovodstvenim i poreznim rješenjima za subjekte koji sami vode poslovne knjige,Savjetovanje iz područja radnih odnosa,Obračun plaća i kadrovske usluge,Obračun plaća,drugog dohotka,dobiti i ostalih isplata,Kadrovske usluge,Vođenje propisanih evidencija iz područja ZOR,Online usluge,Mogućnost uvida u poslovanje 24/7,Razmjene dokumentacije u elektroničkom obliku (mail, cloud),Pristup Fina e-servisu, e-servisima državne uprave i javnih službi,Unos plaćanja u internet bankarstvo,crypto kripto savjetovanje,trgovanje kriptovaluta,porezni tretman kriptovaluta,oporezivanje kriptovaluta"
        />
        <meta name="author" content="Rimatik - Slaven Rađa" />
        <meta name="google-site-verification" content="3ABRko-YoYU7PZ-95VG8UyRTz2WCPEPTLtm4UO4APIU" />
        <title>Računovodstvo Pongrac d.o.o</title>
        <link rel="canonical" href="https://www.racunovodstvopongrac.hr" />
      </Helmet>
      <ScrollingProvider scrollBehavior="smooth">
        <Section id="home">
          <Header />
        </Section>
        <Section id="jumbotron">
          <Jumbotron />
        </Section>
        <Section id="characteristics">
          <Characteristics />
        </Section>
        <Section id="aboutUs">
          <AboutUs />
        </Section>
        <Section id="services">
          <Services />
        </Section>
        <Section id="quote">
          <Quote />
        </Section>
        <Section id="references">
          {" "}
          <References id="references" />
        </Section>
        <Section id="contact">
          {" "}
          <Contact />
        </Section>
        <Section id="footer">
          <Footer />
        </Section>
      </ScrollingProvider>
      <BackToTop />
    </Layout>
  )
}

export default IndexPage
