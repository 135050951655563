import React from "react"
import { SectionLink } from "react-scroll-section"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: "none",
    padding: "0.5rem 0 0.5rem 0",
    color: "#048387",
    fontSize:"19px",
    fontFamily: "Segoe UI",
    position: "relative",
    margin:"0 0.5rem",
    cursor: "pointer",
    "&::before": {
      content: "''",
      height: "2px",
      position: "absolute",
      bottom: "-4px",
      transform: "scale(0)",
      background: "#034669",
      transition: "all 0.4s linear 0s",
      left: 0,
      right: 0,
    },
    "&:hover": {
      "&::before": {
        content: "",
        transform: "scale(1)",
      },
    },
  },
  linkActive: {
    textDecoration: "none",
    padding: "0.5rem 0 0.5rem 0",
    color: "#034669",
    fontSize:"19px",
    fontFamily: "Segoe UI",
    position: "relative",
    margin:"0 0.5rem",
    cursor: "pointer",
    "&::before": {
      content: "''",
      height: "2px",
      position: "absolute",
      bottom: "-4px",
      transform: "scale(1)",
      background: "#034669",
      transition: "all 0.4s linear 0s",
      left: 0,
      right: 0,
    },
  },
}))



const NavItem = ({ section, title }) => {
  const classes = useStyles()

  return (
    <SectionLink section={section} >
      {link => (
        <li
          className={link.isSelected ? classes.linkActive : classes.link}
          onClick={link.onClick}
        >
          {title}
        </li>
      )}
    </SectionLink>
  )
}

export default NavItem;
