import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography, Divider, Avatar } from "@material-ui/core"
import classNames from "classnames"
import PersonOutline from "@material-ui/icons/PersonOutline"
import LibraryBooksOutlined from "@material-ui/icons/LibraryBooksOutlined"
import Bulb from "@material-ui/icons/WbIncandescentOutlined"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F8F8F8",
  },
  container: {
    maxWidth: "1200px",
    margin: "0px auto",
  },
  gridItem: {
    textAlign: "center",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justify: "center",
    alignItems: "center",
    paddingBottom: "2rem",
    paddingTop: "2rem",
  },
  title: {
    fontSize: "32px",
    fontWeight: "700",
    color: "#182A36",
  },
  divider: {
    backgroundColor: "#00806F",
    border: "1px solid #00806F",
    width: "77px",
    height: "2px",
    marginTop: "15px",
  },
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "1rem",
  },
  bigAvatar: {
    width: 108,
    height: 108,
    backgroundColor: "#FFFFFF",
  },
  characteristicsLabel: {
    color: "#00816D",
    fontSize: "24px",
    padding: "1rem",
    fontWeight: 500,
  },
  icon: {
    height: "40px",
    width: "40px",
  },
}))

export default function Jumbotron() {
  const classes = useStyles()

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryCharacteristicsContents {
          data {
            title {
              hr
            }
            learning {
              hr
            }
            individual {
              hr
            }
            innovation {
              hr
            }
          }
        }
      }
    }
  `)

  const characteristics = squidex.queryCharacteristicsContents[0]

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            className={classNames(classes.gridItem, classes.titleContainer)}
            xs={12}
          >
            <Typography className={classes.title}>
              {characteristics.data.title.hr}
            </Typography>
            <Divider className={classes.divider} />
          </Grid>
          <Grid
            item
            className={classes.avatarContainer}
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Avatar className={classes.bigAvatar}>
              <PersonOutline color="primary" className={classes.icon} />
            </Avatar>
            <Typography className={classes.characteristicsLabel}>
              {characteristics.data.individual.hr}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.avatarContainer}
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Avatar className={classes.bigAvatar}>
              <LibraryBooksOutlined color="primary" className={classes.icon} />
            </Avatar>
            <Typography className={classes.characteristicsLabel}>
              {" "}
              {characteristics.data.learning.hr}
            </Typography>
          </Grid>
          <Grid
            item
            className={classes.avatarContainer}
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <Avatar className={classes.bigAvatar}>
              <Bulb color="primary" className={classes.icon} />
            </Avatar>
            <Typography className={classes.characteristicsLabel}>
              {" "}
              {characteristics.data.innovation.hr}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
