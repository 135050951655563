import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import {
  Typography,
  TextField,
  Grid,
  Button,
  Divider,
  CircularProgress,
} from "@material-ui/core"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
const axios = require("axios")
const Recaptcha = require("react-recaptcha")

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#034669",
  },
  container: {
    maxWidth: "1200px",
    margin: "0px auto",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justify: "center",
    alignItems: "center",
    paddingBottom: "2rem",
    paddingTop: "2rem",
  },
  title: {
    fontSize: "32px",
    fontWeight: 700,
  },
  divider: {
    backgroundColor: "#00806F",
    border: "1px solid #00806F",
    width: "77px",
    height: "2px",
    marginTop: "15px",
  },
  subtitle: {
    marginTop: "15px",
    fontSize: "16px",
  },
  formMapContainer: {
    borderBottom: "0.1em solid #D7D7D7",
  },
  formContainer: {
    borderRight: "0.1em solid #D7D7D7",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      border: "none",
    },
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formAfterSubmitMessage: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0.5rem",
  },
  captchaContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "0.5rem",
  },
  contactUsButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "0.5rem",
  },
  contactUsButton: {
    borderRadius: 35,
    borderColor: "grey",
    width: "50%",
    "& p": {
      textTransform: "none",
    },
  },
  textField: {
    paddingBottom: "1rem",
    "& label": {
      color: "white",
    },

    [`& fieldset`]: {
      borderRadius: 35,
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "grey !important",
    color: "white",
  },
  input: {
    color: "#FFFFFF",
    "&:-webkit-autofill": {
      transitionDelay: "9999s",
      transitionProperty: "background-color, color",
    },
    "& focus": {
      color: "#FFFFFF",
    },
  },
  progress: {
    margin: "0.25rem",
    color: "#00806F",
  },
  itemGoogleMaps: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
    height: "650px",
  },
  successMessageLabel: {
    color: "#00806F",
  },
  footerDetails: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    color: "#fff",
    fontFamily: "Segoe UI",
    "& li": {
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      "& img": {
        marginBottom: "0px",
        marginRight: "0.5rem",
      },
    },
  },
  gridItemEmail: {
    borderRight: "0.5px solid #ccc",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      border: "none",
      paddingLeft: "0.5rem",
      justifyContent: "flexstart",
    },
    [theme.breakpoints.down("sm")]: {
      border: "none",
      paddingLeft: "0.5rem",
      justifyContent: "flex-start",
    },
  },
  gridItemLocation: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.5rem",
      justifyContent: "flexstart",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      justifyContent: "flex-start",
    },
  },
  gridItemPhone: {
    display: "flex",
    justifyContent: "center",
    borderLeft: "0.5px solid #ccc",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.5rem",
      justifyContent: "flex-start",
      border: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      justifyContent: "flex-start",
      border: 0,
    },
  },
  emailPhoneAddressLink: {
    textDecoration: "none",
    color: "#fff",
  },
}))

export default function Contact() {
  const classes = useStyles()
  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryContactContents {
          data {
            title {
              hr
            }
            subtitle {
              hr
            }
            emailInfo {
              iv
            }
            emailOne {
              iv
            }
            emailTwo {
              iv
            }
            address {
              hr
            }
            phoneOne {
              iv
            }
            phoneTwo {
              iv
            }
            addressDescription {
              hr
            }
            phoneOneDescription {
              hr
            }
            phoneTwoDescription {
              hr
            }
            nameFormLabel {
              hr
            }
            emailFormLabel {
              hr
            }
            titleFormLabel {
              hr
            }
            contactUsButton {
              hr
            }
            emailIcon {
              iv {
                url
              }
            }
            locationIcon {
              iv {
                url
              }
            }
            phoneIcon {
              iv {
                url
              }
            }
            paycekLogo {
              iv {
                url
              }
            }
            emailInfoSectionTitle {
              hr
            }
            locationInfoSectionTitle {
              hr
            }
            phoneInfoSectionTitle {
              hr
            }
          }
        }
      }
    }
  `)
  const contact = squidex.queryContactContents[0]
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Unesite duže ime")
      .max(128, "Ime predugo")
      .required("Ovo polje je obavezno"),
    title: Yup.string()
      .min(2, "Unesite duži naslov")
      .max(128, "Naslov predug")
      .required("Ovo polje je obavezno"),
    email: Yup.string()
      .email("Nevažeći email")
      .required("Ovo polje je obavezno"),
    description: Yup.string()
      .min(2, "Opis prekratak")
      .max(1024, "Opis predug")
      .required("Ovo polje je obavezno"),
    recaptcha: Yup.string().required("Ovo polje je obavezno"),
  })
  const initialValues = {
    name: "",
    title: "",
    email: "",
    description: "",
    recaptcha: "",
  }
  const instance = axios.create({
    baseURL: "https://racunovodstvo-pongrac-web-api.now.sh/contact",
    timeout: 5000,
    headers: { "Content-Type": "application/json" },
  })

  // var callback = function () {
  //   console.log('Done!!!!');
  // };

  // specifying verify callback function
  // var verifyCallback = function (response) {
  //   console.log(response);
  // };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid xs={12} className={classes.titleContainer}>
          <Typography className={classes.title} color="secondary">
            {contact.data.title.hr}
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.subtitle} color="secondary">
            {contact.data.subtitle.hr}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.formMapContainer}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.formContainer}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    instance
                      .post(
                        "https://racunovodstvo-pongrac-web-api.now.sh/contact",
                        values
                      )
                      .then(function(response) {
                        actions.setStatus({
                          msg: "Upit uspješno poslan",
                          type: "success",
                        })
                      })
                      .catch(function(error) {
                        actions.setStatus({
                          msg: "Dogodila se greška",
                          type: "error",
                        })
                        console.log(error)
                      })
                    actions.setSubmitting(false)
                  }, 5000)
                }}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  status,
                  setFieldValue,
                }) => (
                  <Form className={classes.form}>
                    <Field
                      id="name"
                      aria-describedby="name"
                      label={contact.data.nameFormLabel.hr}
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      component={TextField}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.name && touched.name ? (
                      <Typography
                        color="error"
                        style={{ paddingBottom: "1rem" }}
                      >
                        {errors.name}
                      </Typography>
                    ) : null}
                    <Field
                      id="email"
                      aria-describedby="email"
                      label={contact.data.emailFormLabel.hr}
                      helperText={""}
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      component={TextField}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.email && touched.email ? (
                      <Typography
                        color="error"
                        style={{ paddingBottom: "1rem" }}
                      >
                        {errors.email}
                      </Typography>
                    ) : null}
                    <Field
                      id="title"
                      aria-describedby="title"
                      label={contact.data.titleFormLabel.hr}
                      helperText={""}
                      variant="outlined"
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      component={TextField}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.title && touched.title ? (
                      <Typography
                        color="error"
                        style={{ paddingBottom: "1rem" }}
                      >
                        {errors.title}
                      </Typography>
                    ) : null}
                    <Field
                      id="description"
                      aria-describedby="description"
                      helperText={""}
                      variant="outlined"
                      multiline={true}
                      rows={5}
                      className={classes.textField}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                          input: classes.input,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      component={TextField}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                    />
                    {errors.description && touched.description ? (
                      <Typography
                        color="error"
                        style={{ paddingBottom: "1rem" }}
                      >
                        {errors.description}
                      </Typography>
                    ) : null}
                    <div className={classes.captchaContainer}>
                      <Recaptcha
                        sitekey="6Leo0a8UAAAAAJDWr2YwrhflEOIEio3XKOP0p6T6"
                        verifyCallback={response => {
                          setFieldValue("recaptcha", response)
                        }}
                        size="normal"
                      />
                    </div>
                    {errors.recaptcha && touched.recaptcha ? (
                      <Typography color="error" style={{ textAlign: "right" }}>
                        {errors.recaptcha}
                      </Typography>
                    ) : null}
                    <div className={classes.contactUsButtonContainer}>
                      <Button
                        className={classes.contactUsButton}
                        variant="outlined"
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {!isSubmitting ? (
                          <Typography>
                            {contact.data.contactUsButton.hr}
                          </Typography>
                        ) : (
                          <CircularProgress
                            className={classes.progress}
                            variant="indeterminate"
                            size={20}
                          />
                        )}
                      </Button>
                    </div>
                    <div className={classes.formAfterSubmitMessage}>
                      {status && status.msg && status.type === "success" && (
                        <Typography className={classes.successMessageLabel}>
                          {status.msg}
                        </Typography>
                      )}
                      {status && status.msg && status.type === "error" && (
                        <Typography color="error">{status.msg}</Typography>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.itemGoogleMaps}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2755.0011657744058!2d16.61674961578843!3d46.329671083296795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4768a5ec95256b35%3A0x46152bc3ab031b85!2sRa%C4%8Dunovodstvo%20Pongrac%20d.o.o.!5e0!3m2!1shr!2shr!4v1567872758873!5m2!1shr!2shr"
                style={{ border: 0, width: "100%", height: "100%" }}
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: "1rem 0 1rem 0" }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignContent="center"
          >
            <Grid
              item
              container
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className={classes.gridItemEmail}
            >
              <div>
                <Typography
                  style={{ paddingBottom: "0.5rem" }}
                  color="secondary"
                >
                  {contact.data.emailInfoSectionTitle.hr}
                </Typography>
                <ul className={classes.footerDetails}>
                  <li>
                    <a
                      href={"mailto:" + contact.data.emailInfo.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      <img src={contact.data.emailIcon.iv[0].url} alt="email" />
                    </a>
                    <a
                      href={"mailto:" + contact.data.emailInfo.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {" "}
                      {contact.data.emailInfo.iv}
                    </a>
                  </li>
                  <li>
                    <a
                      href={"mailto:" + contact.data.emailOne.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {" "}
                      <img src={contact.data.emailIcon.iv[0].url} alt="email" />
                    </a>
                    <a
                      href={"mailto:" + contact.data.emailOne.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {contact.data.emailOne.iv}
                    </a>
                  </li>
                  <li>
                    <a
                      href={"mailto:" + contact.data.emailTwo.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      <img src={contact.data.emailIcon.iv[0].url} alt="email" />
                    </a>
                    <a
                      href={"mailto:" + contact.data.emailTwo.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {contact.data.emailTwo.iv}
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              container
              className={classes.gridItemLocation}
            >
              <div>
                <Typography
                  style={{ paddingBottom: "0.5rem" }}
                  color="secondary"
                >
                  {contact.data.locationInfoSectionTitle.hr}
                </Typography>
                <ul className={classes.footerDetails}>
                  <li>
                    <a
                      href="https://www.google.com/maps/place/Ul.+Jug+II+32,+40323,+Prelog/@46.3296711,16.6167496,17z/data=!3m1!4b1!4m5!3m4!1s0x4768a44a30ff2963:0x8743a6e08ef78d91!8m2!3d46.3296674!4d16.6189383?hl=hr"
                      className={classes.emailPhoneAddressLink}
                    >
                      <img
                        src={contact.data.locationIcon.iv[0].url}
                        alt="location"
                      />{" "}
                    </a>
                    <a
                      href="https://www.google.com/maps/place/Ul.+Jug+II+32,+40323,+Prelog/@46.3296711,16.6167496,17z/data=!3m1!4b1!4m5!3m4!1s0x4768a44a30ff2963:0x8743a6e08ef78d91!8m2!3d46.3296674!4d16.6189383?hl=hr"
                      className={classes.emailPhoneAddressLink}
                    >
                      {" "}
                      {contact.data.addressDescription.hr}:&nbsp;
                      {contact.data.address.hr}
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid
              item
              container
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className={classes.gridItemPhone}
            >
              {" "}
              <div>
                <Typography
                  style={{ paddingBottom: "0.5rem" }}
                  color="secondary"
                >
                  {contact.data.phoneInfoSectionTitle.hr}
                </Typography>
                <ul className={classes.footerDetails}>
                  <li>
                    <a
                      href={"tel:" + contact.data.phoneOne.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {" "}
                      <img
                        src={contact.data.phoneIcon.iv[0].url}
                        alt="phone"
                      />{" "}
                    </a>
                    <a
                      href={"tel:" + contact.data.phoneOne.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {" "}
                      {contact.data.phoneOneDescription.hr}:&nbsp;
                      {contact.data.phoneOne.iv}
                    </a>
                  </li>
                  <li>
                    <a
                      href={"tel:" + contact.data.phoneTwo.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {" "}
                      <img
                        src={contact.data.phoneIcon.iv[0].url}
                        alt="phone"
                      />{" "}
                    </a>
                    <a
                      href={"tel:" + contact.data.phoneTwo.iv}
                      className={classes.emailPhoneAddressLink}
                    >
                      {" "}
                      {contact.data.phoneTwoDescription.hr}:&nbsp;
                      {contact.data.phoneTwo.iv}
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
