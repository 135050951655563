import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#002F47",
  },
  container: {
    maxWidth: "1200px",
    maxHeight: "150px",
    margin: "0 auto",
    padding: "1rem 0rem 1rem 0rem",
  },
  gridItem:{
    display: "flex",
     justifyContent: "center",
     alignItems: "center",
  },
  footerLabel:{
    fontFamily: "Segoe UI",
    fontSize: "16px"
  },
  cryptoLogoLink:{
    cursor: "pointer"
  },
  cryptoLogo:{
    marginBottom: 0, 
    width: "100px" 
  },
  paycekBanner:{
    marginBottom: 0, 
    width: "175px",
    height: "35px",
    paddingRight: "5px"
  },
  companyBasicInfoText:{
    fontFamily: "Segoe UI",
    paddingTop: "0.25rem",
    paddingLeft: "1rem",
    fontSize: "11px",
    color: "grey"
  },
}))

export default function Footer() {
  const classes = useStyles()

  const { squidex } = useStaticQuery(graphql`
    query {
      squidex {
        queryFooterContents {
          data {
            copyrightText {
              hr
            }
            poweredByText {
              hr
            }
            poweredByHelperText {
              hr
            }
            paycekLogo {
              iv {
                url
              }
            }
            paycekBanner {
              iv {
                url
              }
            }
            companyBasicInfoText{
              hr
            }
          }
        }
      }
    }
  `)

  const footer = squidex.queryFooterContents[0]

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid
          item
          md={4}
          xs={12}
        className={classes.gridItem}
        >
          <Typography
            color="secondary"
           className={classes.footerLabel}
          >
            {footer.data.copyrightText.hr}
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          className={classes.gridItem}
        >
          <Typography
            color="secondary"
            className={classes.footerLabel}
          >
            {footer.data.poweredByHelperText.hr}:&nbsp;
            {footer.data.poweredByText.hr}
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          className={classes.gridItem}
        >
            <a href="https://paycek.io/" className={classes.cryptoLogoLink}>
            <img
              src={footer.data.paycekBanner.iv[0].url}
              alt="paycekBanner"
             className={classes.paycekBanner}
            />
          </a>
          &nbsp;
          <a href="https://paycek.io/" className={classes.cryptoLogoLink}>
            <img
              src={footer.data.paycekLogo.iv[0].url}
              alt="paycek"
             className={classes.cryptoLogo}
            />
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.gridItem}
        >
          <Typography
            color="secondary"
            className={classes.companyBasicInfoText}
          >
            {footer.data.companyBasicInfoText.hr}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
